import React from 'react';
import pic1 from './lakelouise_sunrise.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={pic1} className="App-pic1" alt="pic1" />
        <p>
          victoria
        </p>
        <a
          className="App-link"
          href="https://www.hello.com"
          target="_blank"
          rel="noopener noreferrer"
        >
        </a>
      </header>
    </div>
  );
}

export default App;
